<template>
  <div>
    <div class="sidebar" :class="{ active: isActive }">
      <div class="sidebar-content">
        <button class="icon" v-for="icon in icons" :key="icon.name" @click="sendevent(icon.name)">
          <img :src="icon.src" alt="icon.name" />
        </button>
      </div>
      <button type="button" class="toggle-button" @click="toggleSidebar">
        <span>{{ isActive ? '<<' : '>>' }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent} from 'vue';
import ShoppingCart from '../assets/shopping-cart.jpg';
import profile from '../assets/profile.png'

export default defineComponent({
  name: 'SideBar',
  emits: ['ShoppingCart', 'profile'],
  setup(props, {emit}) {
    const isActive = ref(false);
    const icons = [
      { name: 'ShoppingCart', src: ShoppingCart },
      { name: 'profile', src: profile }
    ];


    const sendevent = (clickevent) => {
      emit(`${clickevent}`);
    };
    const toggleSidebar = () => {
      isActive.value = !isActive.value;
    };

    return {
      isActive,
      icons,
      sendevent,
      toggleSidebar,
      ShoppingCart,
      profile
    };
  }
});
</script>

<style scoped>
.sidebar {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 50px;
  height: 300px;
  background-color: #333;
  overflow: hidden;
  transition: width 0.3s ease;
}

.sidebar.active {
  width: 200px;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.icon {
  margin: 10px 0;
}

.icon img {
  width: 30px;
  height: 30px;
}

.toggle-button {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%);
  width: 25px;
  height: 50px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.toggle-button span {
  display: block;
  text-align: center;
}
</style>
