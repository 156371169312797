<template>

    <div class="cover"><img class="cover" :src="DreamWorkLogo" alt="I'm Mario"/></div>
    <div class="container">     
        
        <div class="content">
            <div class="menu">
                <div v-for="(tab, index) in Labels.arr" :key="index" class="label" @click="SwitchProduct(index)">
                {{ tab }}
                    <div v-if="index !== Labels.arr.length - 1" class="separator"></div>
                </div>
            </div>
            <div class="items">
                <div v-for="(product, index ) in CurrentProduct" :key="index" class="item">
                    <img  class="image"  alt=""  
                        :src="ProductSrc.arr[index]"
                        @mouseover="updateImageSrc(product.id, '2', index)"
                        @mouseleave="updateImageSrc(product.id, '1', index)" />
                    <div>    
                        <h1  >{{product.name}}</h1>
                        <p>${{product.price}} </p>
                    </div>
                    <button @click="ItemDisplay(product)">立即購買</button>
                </div>
            </div>
            <div class="media">
                <a href="https://www.instagram.com/dream_work_design/" ><img :src="dwig" alt="dream_work_design"  class="mediaicon"></a>
            </div>
        </div>
    </div>
    <SideBar @ShoppingCart="PageOC(ShoppingCart)" />

    
    <ItemDisplay1 v-if="ItemDisplay__"  ref="ItemRef" :item="selectedItem"  @update:Cart="PushIntoCart"/>
    <ShoppingList v-if="ShoppingCart.boolean" :cart="cart" :UserId="Id" @update:input="BuyNumberUpdate" @remove:cart="RemovefromCart"/>
    
</template>

<script>
import { defineComponent, ref, reactive, onMounted, onBeforeUnmount } from 'vue';
import SideBar from './SideBar.vue';
import ItemDisplay1 from './ItemDisplay1.vue';
import ShoppingList from './ShoppingList.vue';
import DreamWorkLogo from '@/assets/Logo.jpg';
import dwig from '@/assets/dream_work_design_qr.jpg';
import labels from '@/assets/Labels.json';
import product1 from '@/assets/product1.json';
import product2 from '@/assets/product2.json';
import product3 from '@/assets/product3.json';
import product4 from '@/assets/product4.json';
import product5 from '@/assets/product5.json';

export default defineComponent({
    name: 'HomePage',
    components: {
        SideBar,
        ItemDisplay1,
        ShoppingList
    },
    setup() {
        const ShoppingCart = reactive({
            boolean: false
        });
        const CurrentProduct = ref(product2);

        const SwitchProduct = (index) => {
            switch (index) {
                case 0: {
                    CurrentProduct.value = product1;
                    break;
                }
                case 1: {
                    CurrentProduct.value = product2;
                    break;
                }
                case 2: {
                    CurrentProduct.value = product3;
                    break;
                }
                case 3: {
                    CurrentProduct.value = product4;
                    break;
                }
                case 4: {
                    CurrentProduct.value = product5;
                    break;
                }
            }
            loadInitialImages();
        }

        const getImageSrc =  async  (id, version) => {
            try {
                const image = await import(`@/assets/${id}/${version}.jpg`);
                return image.default;
            } catch (error) {
                console.error(`Error loading image: ${error}`);
                return '';  
            }
        };

        const loadInitialImages = async () => {
            for (let i = 0; i < CurrentProduct.value.length; i++) {

                ProductSrc.arr[i] = await getImageSrc(CurrentProduct.value[i].id, '1');
            }
        };
        const updateImageSrc = async (id, version, index) => {
            ProductSrc.arr[index] =  await getImageSrc(id, version);
        };
        const ProductSrc = reactive({arr: []});
        const selectedItem = reactive({});
        const ItemRef = ref(null);
        const ItemDisplay__ = ref(false);
        const Items = reactive({arr: []});
        const Labels = reactive({arr: labels});
        const cart = reactive({arr: []});
        
        
        const ItemDisplay = (item) => { //HomePage
            
            setTimeout(() => {   
                Object.assign(selectedItem, item);           
                ItemDisplay__.value = !ItemDisplay__.value;               

            },20);
        }   
        const PageOC = (event) => { //Open and close
            event.boolean = !event.boolean;
            console.log(event);
        };
        

        const ClickOutside = (event) => {
            if(ItemRef.value && !ItemRef.value.$el.contains(event.target)) {

                ItemDisplay__.value = !ItemDisplay__.value;
            }
        }

        const BuyNumberUpdate = (payload) => {
            const index = payload.index;
            cart.arr[index].quantity = payload.value;
            console.log(1);
        }
        const PushIntoCart = (payload) => {
            const exist = cart.arr.some(i => i.Id === payload.Id);
            if(!exist) {
                cart.arr.push(payload);
            }
        }
        const RemovefromCart = (payload) => {

            cart.arr.splice(payload, 1);
        }

        const handleDeleteItem = (payload) => {
            Items.value.arr = payload;
        };


        

        onMounted(() => {
            loadInitialImages();
            document.addEventListener('click', ClickOutside);
        });

        onBeforeUnmount(() => {
            document.removeEventListener('click', ClickOutside);
        });


        const ItemList  = reactive([]);
        const CoverPicture = ref('');


        return {
            ItemRef,
            Items,
            Labels,
            ShoppingCart,
            BuyNumberUpdate,
            PushIntoCart,
            RemovefromCart,
            ItemDisplay__,
            PageOC,
            ItemList,
            CoverPicture,
            cart,
            handleDeleteItem,
            ItemDisplay,
            selectedItem,
            CurrentProduct,
            SwitchProduct,
            ProductSrc,
            updateImageSrc,
            DreamWorkLogo,
            dwig
        };
    },

    
});

</script>

<style>
.cover {

    max-height: 480px;
    width:auto;
    height:auto;
}

.image {
    max-width: 360px;
    max-height: 360px;
    width:auto;
    height:auto;
}



html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
}

.cover, .footer {
  background-color: #f8f8f8;
  padding: 20px;
  text-align: center;
}




.content {
    position:relative;
  flex: 1;
  background-color: #ffffff;
  padding: 20px;
  justify-content: center;
    align-items: center;
}

.menu {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  justify-content: center;
  background-color: #e0e0e0;
}

.label {
  position: relative;
  flex: 1 0 calc(100% / 7 - 10px);
  text-align: center;
  padding: 10px;
  margin: 5px;
  background-color: #f0f0f0;
  border-radius: 5px;
  transition: transform 0.3s, background-color 0.3s;
}

.label:hover {
  transform: scale(1.1);
  background-color: #e0e0e0;
}

.separator {
  position: absolute;
  right: -5px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ccc;
}

.items {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 四列 */
    gap: 20px; /* 每个项目之间的间距 */
    width: 85%; /* 宽度为80% */
    margin: 0 auto; /* 自动水平居中 */
    background-color: lightblue;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.item {
    background-color: white;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mediaicon {
    max-width: 100px;
    max-height: 100px;
    width:auto;
    height:auto;
}



</style>