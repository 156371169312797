<template>
    <div class="container">
        <div class="otherinfo">
            <img class="image" :src="ImageSrc" alt="item_.name"/>
            <h1>{{item_.name}}</h1>
            <input type="number" class="quantity" v-model="buynumber" id="buynumber" value="1" required>
            <button class="buybutton" @click="Buy" ><img class="buybutton" :src="ShoppingCart" alt="購買此商品"></button>
        </div>
        
        <div>
            <label class="textinfo">
                <h2>材質與尺寸:</h2>
                <ul>
                    <li v-for="(option, index) in item_.material" :key="index">
                        <input type="radio" :value="index" v-model="Material"/>
                        {{option.name}} (+{{ option.add }})
                    </li>
                </ul>
                <h2>雕刻:</h2>
                <ul>
                    <li v-for="(option, index) in item_.sculpture" :key="index">
                        <input type="radio" :value="index" v-model="Sculpture"/>
                        {{option.name}} (+{{ option.add }})
                    </li>
                </ul>
            </label>
            <div>
                <h3>Total Price: {{ UnitAmount }} HKD</h3>
            </div>
        </div>
        
        
    </div>
</template>



<script>
import {defineComponent, ref, reactive, toRefs,computed, onMounted} from 'vue';
import ShoppingCart from '../assets/shopping-cart.jpg';

export default defineComponent ({
    name: 'ItemDisplay1',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    emits:["update:Cart"],
    setup(props, {emit}) {
        const { item } = toRefs(props);
        const item_ = reactive({ ...item.value });
        const Material =ref(0);
        const Sculpture = ref(0);

        const UnitAmount = computed(() => {
            const materialPrice = item_.material?.[Material.value]?.add || 0;
            const sculpturePrice = item_.sculpture?.[Sculpture.value]?.add || 0;
            return item_.price + materialPrice + sculpturePrice;
        });
        
        const buynumber = ref(1);

        const ImageSrc = ref(null);
        const getImageSrc =  async  (id, version) => {
            try {
                const image = await import(`@/assets/${id}/${version}.jpg`);
                return image.default;
            } catch (error) {
                console.error(`Error loading image: ${error}`);
                return '';  
            }
        };
        const Buy = () => {        
            
            emit("update:Cart", {"Id": item_.id, "name":item_.name, "Material":item_.material[Material.value].name, "Sculpture":item_.sculpture[Sculpture.value].name, "quantity": buynumber.value, "price":UnitAmount.value});    
        };

        onMounted(async () => {

            ImageSrc.value = await getImageSrc(item_.id, '1');
            
        })
        
        return {
            item_,
            buynumber,
            Buy,
            ShoppingCart,
            Material,
            Sculpture,
            ImageSrc,
            UnitAmount
        };
    }
});
</script>

<style scoped>

.container {
    position: fixed;
    display: flex;
  flex-direction: row;
    top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  transform: translate(-50%, -50%); /* 调整位置，使其完全居中 */
  background-color: white; /* 设置背景颜色，方便查看效果 */
  z-index: 1000; /* 确保在最上层显示 */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
  width: 800px;
  min-height: 600px;
  align-items: center; /* 垂直居中 */
    justify-content: space-between;
}

.image {
    max-width: 160px;
    max-height: 160px;
    width:auto;
    height:auto;
}

.textinfo {
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center; /* 垂直居中 */
      
}
.otherinfo {
    padding: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center; /* 垂直居中 */
    justify-content: space-between;
}

.quantity {
    width: 200px;
}

.buybutton {
    max-width:200px;
    max-height: 60px;
    width: auto;
    height:auto;
    align-items: center;
}


.price {
    font-weight: bold;
    color: green;
}
</style>