<template>
  <div class="cart-container">
    <div class="cart-items">
      <ul>
        <li class="product" v-for="(item, index) in localValue.arr" :key="index" >
          <p>{{item.name}} ({{item.Material}}) / {{item.Sculpture}}</p>
          <p>${{item.price}}</p>
          <div class="quantity">
            <label for="quantity">Quantity:</label>
            <input type="number" id="quantity" :value="item.quantity" @input="updateValue(item.Id, $event.target.value)" required>
          </div>
          <button class="deletebutton" @click="removeFromCart(index)">
            <img class="symbol" :src="minusSymbol" alt="delete this product" >
          </button>
        </li>
      </ul>
    </div>
    <div class="total-amount">Total Amount: ${{totalAmount}}</div>
    <div id="paypal-button-container"></div>
  </div>
</template>





<script>
import { defineComponent, ref, onMounted, watch, computed } from 'vue';
import {loadScript} from '@paypal/paypal-js';
import ShoppingCart from '../assets/shopping-cart.jpg';
import minusSymbol from '../assets/minusSymbol.png';

export default defineComponent({
  name: 'ShoppingList',
  props: {
    UserID: {
      type: Number,
      required: true
    },
    cart: {
      type: Object,
      required: true
    }
  },
  emits: ['update:value', 'remove:cart'],
  setup(props, { emit }) {

    const localValue = ref(props.cart);

    const Ids = computed(() => {
      return localValue.value.arr.map(item => item.Id);
    });
    const quantities = computed(() => {
      return localValue.value.arr.map(item => item.quantity);
    });

    // 當 props 發生變化時更新本地狀態
    watch(() => props.cart, (newValue) => {
      localValue.value = newValue;
    });

    function updateValue(index, newValue) {
      emit('update:input', { "Index": index, "value": newValue });
    }

    const totalAmount = computed(() => {
      return localValue.value.arr.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2);
    });

    const removeFromCart = (index) => {
      if (index !== -1) {
        emit('remove:cart', index);
      }
    };

    const Buy = async (userid, id, quantity) => {
      try {
        const response = await fetch('/api/buy', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userid: userid, Id: id, number: quantity })
        });
        console.log(response.message);
      } catch (error) {
        console.error(error.message);
      }
    };

    onMounted(async () => {
      // 确保 PayPal SDK 已经加载
      const paypal = await loadScript({
        'clientId': process.env.VUE_APP_CILENT_ID,
        currency: 'HKD'
      });

      paypal.Buttons({
          createOrder: function (data, actions) {
            const purchaseUnits = localValue.value.arr.map(item => ({
              name: `${item.name}(${item.Material}) + ${item.Sculpture}`,
              unit_amount: {
                value: item.price.toString(),
                currency_code: 'HKD'
              },
              quantity: item.quantity.toString()
            }));

            const itemTotal = localValue.value.arr.reduce((total, item) => total + (item.price * item.quantity), 0);

            return actions.order.create({
              purchase_units: [{
                amount: {
                  value: totalAmount.value,
                  currency_code: 'HKD',
                  breakdown: {
                    item_total: {
                      value: itemTotal.toString(),
                      currency_code: 'HKD'
                    }
                  }
                },
                items: purchaseUnits
              }]
            });
          },
          onApprove: function (data, actions) {
            return actions.order.capture().then(function (details) {
              alert('Transaction completed by ' + details.payer.name.given_name);
              Buy(props.UserID, Ids.value, quantities.value);
            });
          }
        }).render('#paypal-button-container');
    });

    return {
      Buy,
      ShoppingCart,
      localValue,
      updateValue,
      totalAmount,
      removeFromCart,
      minusSymbol
    };
  }
});
</script>


<style scoped>
.cart-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px; /* 调整为合适的宽度 */
  height: 100%;
  background-color: #f8f8f8;
  box-shadow: -2px 0 5px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.cart-items {
  flex: 1;
  overflow-y: auto; /* 超出范围的内容滚动显示 */
}

.cart-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.product {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.quantity {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.quantity label {
  margin-right: 10px;
}

.symbol {
  width: 16px;
  height: 16px;
}

.deletebutton {
  background: none;
  border: none;
  cursor: pointer;
}

.total-amount {
  padding: 10px 0;
  font-weight: bold;
  text-align: center;
  border-top: 1px solid #ccc;
}

#paypal-button-container {
  padding: 20px 0;
  text-align: center;
}
</style>

